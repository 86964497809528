export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    access: 'full',
  },
  {
    title: 'Notifications',
    route: 'todo',
    icon: 'AlertTriangleIcon',
  },
  {
    header: 'Tâches',
  },
  {
    title: 'Mon tableau de bord',
    route: 'myTasks',
    icon: 'HardDriveIcon',
    access: 'sales',
  },
  {
    title: 'Mes tâches',
    route: 'tasks',
    icon: 'HardDriveIcon',
    access: 'sales',
  },
  {
    title: 'Création',
    route: 'createTask',
    icon: 'UserPlusIcon',
    access: 'sales',
  },
  {
    header: 'Boutique',
  },
  {
    title: 'Encaissement',
    route: 'encaissement',
    icon: 'HardDriveIcon',
    access: 'sales',
  },
  {
    title: 'Compteur',
    route: 'counter',
    icon: 'UserPlusIcon',
    access: 'sales',
  },
  {
    title: 'Mes perfs',
    route: 'vendor-detail',
    icon: 'AirplayIcon',
    access: 'sales',
  },
  {
    title: 'Vente',
    route: 'vente',
    icon: 'LayersIcon',
  },
  {
    title: 'Devis',
    route: 'devis',
    icon: 'FileTextIcon',
  },
  // {
  //   title: 'Stock',
  //   route: 'stock',
  //   icon: 'ArchiveIcon',
  // },
  {
    header: 'E-Commerce',
    access: 'compta',
  },
  {
    title: 'Commande',
    route: 'commande',
    icon: 'ShoppingCartIcon',
    access: 'compta',
  },
  {
    title: 'Création commande',
    route: 'encaissementsav',
    icon: 'HardDriveIcon',
  },
  {
    title: 'Echouées',
    icon: 'FrownIcon',
    children: [
      {
        title: 'Commande',
        route: 'echouees',
      },
      {
        title: 'Rapport Echouée',
        route: 'report-failed',
      },
      {
        title: 'Paiement Echoué',
        route: 'Paiement échoués',
      },
    ],
  },
  {
    title: 'Waiting List',
    route: 'customerAlert',
    icon: 'CoffeeIcon',
  },
  {
    title: 'Code promo',
    route: 'coupon',
    icon: 'GiftIcon',
  },
  {
    title: 'Orders Alert',
    icon: 'AlertOctagonIcon',
    children: [
      {
        title: 'Out of stock ',
        route: 'ordersOutOfStock',
      },
    ],
  },
  {
    title: 'Search Data',
    route: 'search-data',
    icon: 'SearchIcon',
  },
  {
    title: 'Claims',
    route: 'claims',
    icon: 'FrownIcon',
  },
  {
    title: 'Sinistre',
    route: 'sinistreOrder',
    icon: 'LayersIcon',
  },
  {
    header: 'Admin',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
  },
  {
    header: 'Site',
  },
  {
    title: 'Page',
    icon: 'CodepenIcon',
    children: [
      {
        title: 'Produits',
        route: 'datas',
      },
      {
        title: 'Produits New SITE',
        route: 'productsV2',
      },
      {
        title: 'Produits Textile',
        route: 'productTextile',
      },
      {
        title: 'FAQ Produit',
        route: 'productsFaq',
      },
      {
        title: 'Blog',
        route: 'blog',
      },
      {
        title: 'Guide',
        route: 'guide',
      },
      {
        title: 'Categories',
        route: 'categories',
      },
      {
        title: 'Boutiques',
        route: 'page-boutique',
      },
      {
        title: 'Alertes',
        route: 'alerts',
      },
    ],
  },
  {
    title: 'Calendrier',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
  {
    header: 'Logistique',
  },
  {
    title: 'Logistique',
    route: 'logistic',
    icon: 'TruckIcon',
    access: 'logistic',
  },
  {
    title: 'Details',
    route: 'logisticDetails',
    icon: 'FileIcon',
  },
  {
    title: 'Stock site',
    route: 'stock-online',
    icon: 'PackageIcon',
  },
  {
    title: 'Prévisionnel',
    route: 'logisticStock',
    icon: 'AlertOctagonIcon',
  },
  {
    title: 'Commande Fournisseur',
    route: 'logisticOrder',
    icon: 'AlertOctagonIcon',
  },
  {
    title: 'Import',
    icon: 'UploadIcon',
    children: [
      {
        title: 'Order',
        route: 'importOrder',
      },
      {
        title: 'BL',
        route: 'importbl',
      },
      {
        title: 'SKU Detail',
        route: 'importcompose',
      },
      {
        title: 'Shipping Cost',
        route: 'shippingCost',
      },
    ],
  },
  {
    title: 'Retour',
    route: 'return',
    icon: 'CornerUpLeftIcon',
  },
  {
    header: 'Dispatch',
  },
  {
    title: 'Alertes',
    icon: 'AlertOctagonIcon',
    children: [
      {
        title: 'AGEDISS',
        route: 'logisticWarning',
      },
      {
        title: 'CAR',
        route: 'ordersInfoProblem',
      },
    ],
  },
  {
    title: 'Historic',
    route: 'logisticDispatch',
    icon: 'FileIcon',
  },
  {
    title: 'Dispatch',
    route: 'dispatch',
    icon: 'ShuffleIcon',
  },
  {
    title: 'Suivi',
    route: 'itemsDispatch',
    icon: 'ActivityIcon',
  },
  {
    header: 'Analytics',
  },
  {
    title: 'Gorgias',
    route: 'gorgias',
    icon: 'MessageCircleIcon',
  },
  {
    title: 'Acquisition',
    route: 'sea',
    icon: 'BarChart2Icon',
  },
  {
    title: 'Produits',
    route: 'products',
    icon: 'LayersIcon',
  },
  {
    title: 'Ecommerce',
    route: 'ecommerce',
    icon: 'LayersIcon',
  },
  {
    header: 'SAV',
  },
  {
    title: 'Solution',
    route: 'solutionList',
    icon: 'SmileIcon',
  },
  {
    header: 'Compta',
    access: 'compta',
  },
  {
    title: 'Export',
    route: 'exportCompta',
    icon: 'SmileIcon',
    access: 'compta',
  },
]
